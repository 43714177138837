import React, { Component } from 'react';
import './App.css';
import { withLocalize } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import globalTranslations from './translations/global.json'
import {Switch, Route, withRouter, Redirect} from 'react-router-dom'
import Main from './Main';
import Header from './components/Header';
import Footer from './components/Footer';
import { withStyles, withTheme } from "@material-ui/core/styles";
import Title from "./components/Title";
import { Paper, Grid } from "@material-ui/core";
import UpdateSubscription from './UpdateSubscription';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        background: 'no-repeat center center fixed',
        minHeight: '100%',
        width: '100%'
    },
    body: {
        width: '100%',
        margin: '0 auto',
        padding: '50px',
        boxSizing: 'border-box'
    }
});

const languages = [
    { name: "English", code: "en" },
    { name: "French", code: "fr" }
];

class App extends Component {
    constructor(props){
        super(props);

        this.props.initialize({
            languages: languages,
            translation: globalTranslations,
            options: {
                renderToStaticMarkup,
                renderInnerHtml: true,
                defaultLanguage: languages.map(a => a.code)
                    .indexOf(this.props.location.pathname.substring(1)) !== -1
                    ? this.props.location.pathname.substring(1) : 'en'
            }
        });
    }
    
  render() {
    const { classes } = this.props;
    console.log(this.props);
    return (
        <div className={classes.root}>
            <Header />
            <Grid container>
                <Grid item md={2} lg={3} />
                <Grid item xs={12} md={8} lg={6} >
                    <Paper className={classes.body}>
                        <Title />
                        <Switch>
                            <Route exact path="/" component={Main}/>
                            <Route exact path="/connect-preference-center" component={UpdateSubscription}/>
                            <Route exact strict path="/connect-preference-center" component={Main}/>
                        </Switch>
                    </Paper>
                </Grid>
                <Grid item md={2} lg={3} />
            </Grid>
            <Footer />
        </div>
    );
  }
}

export default withRouter(withTheme()(withStyles(styles)(withLocalize(App))));
