import React, { Component } from 'react';
import {withStyles, withTheme} from "@material-ui/core/styles";
import {TextField} from "@material-ui/core";
import { Translate } from "react-localize-redux";
import config from '../config';

const styles = ( ) => ({
    input: {
        borderRadius: '2px',
        width: '100%'
    },
    label: {

    }
});

class PureTextInput extends Component {

    // state = {
    //     value:''
    // }
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
        this.onInputChange = this.onInputChange.bind(this)
    }

    // componentDidMount(){
    //     this.setState({ value: this.props.value });
    // }

    onInputChange= (event) => {
        // console.log(this.props)
        if(event.target.name){
        // console.log(event.target.value);
        }
        this.setState({          
          value: event.target.value
        });
        this.props.onInputChange(event);
      };

      
    
      

    render(){

        const { name, label, classes, value, disable } = this.props;

        // this.state = {
        //     value: this.props.value
        // };


        // const inputProps = {
        //     // readOnly: false,
        //     defaultValue: (this.state.value === '') ? value : this.state.value,
        //     // disabled: true
        // };
        
        
        // value = '';

        return(
            <Translate>
                {({ translate }) => (
                <TextField
                    label={label}
                    variant={config.fieldSettings.defaultVariant}
                    id={name}
                    name={name}
                    disabled={disable}
                    value={(this.state.value === '' && !value) ? '' : this.state.value === '' ? value : this.state.value}
                    onChange={this.onInputChange}
                    className={classes.input}
                />
                )}
                </Translate>
            
        )
    }
}

const TextInput = withTheme()(withStyles(styles)(PureTextInput));
// const TextInput = (PureTextInput);

export { TextInput }

