const postBody = {
  //testing
  email: "",
  id: "",
  fields: [
    {
      name: "Language",
      value: "English"
    },
    {
      name: "UniqueID",
      value: ""
    }
  ],
  subscriptionPermissions: [
    {
      name: "CCME News & Information",
      permission: "Unassigned",
    },
    {
        name: "AFMC News",
        permission: "Unassigned",
    },
    {
        name: "AFMC Member Business",
        permission: "Unassigned",
    },
    {
        name: "CAPER News",
        permission: "Unassigned",
    }
  ]
}    
export default postBody;
// const postBody = {
// //testing
// email: "",
// id: "",
// fields: [
//   {
//     name: "party_id",
//     value: ""
//   }
// ],
// subscriptionPermissions: [
//   {
//     name: "General",
//     permission: "Unassigned",
//   },
//   {
//       name: "Nathan's Newsletter",
//       permission: "Unassigned",
//   },
// ]
// };

// export default postBody;

  // New 
  // const postBody = {
  //   //testing
  //   email: "",
  //   id: "",
  //   fields: [
  //     {
  //       "field": {
  //         "id": 15,
  //         "name": "Language",
  //         "type": "text",
  //         "required": false
  //       },
  //     "value": "English"
  //     },
    //   {
    //     field: {
    //         id: 1,
    //         name: "UniqueID",
    //         type: "Text",
    //         required: true,
    //         isIdentifier: true
    //     },
    //     value: ""
    //   },
  //   ],
  //   subscriptionPermissions: [
  //     {
  //       name: "CCME News & Information",
  //       permission: "Unassigned",
  //     },
  //     {
  //         name: "AFMC News",
  //         permission: "Unassigned",
  //     },
  //     {
  //         name: "AFMC Member Business",
  //         permission: "Unassigned",
  //     },
  //     {
  //         name: "CAPER News",
  //         permission: "Unassigned",
  //     }
  //   ]
  //   };
    
  //   export default postBody;

  // fields: [
  //   {
  //     field: {
  //       id: 15,
  //       name: "Language",
  //       type: "text",
  //       required: false
  //     },
  //   value: "English"
  //   },
  //   {
  //     field: {
  //         id: 1,
  //         name: "UniqueID",
  //         type: "Text",
  //         required: true,
  //         isIdentifier: true
  //     },
  //     value: ""
  //   },
  // ],