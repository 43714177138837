import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid'
import { withStyles } from "@material-ui/core/styles";
import Logo from './Logo'
import LanguageToggle from "./LanguageToggle";

const styles = {
    container: {
        padding: '20px'
    },
    logo: {
        display: 'inherit',
        margin: '0 auto',
        maxWidth: '500px',
        width: '100%'
    }
};

class Header extends Component {
    
    render(){
        const { classes } = this.props;
        return(
            <Grid container justify='center' className={classes.container}>
                <Grid item md={2} lg={3} />
                <Grid item xs={12} md={8} lg={6} >
                    <Grid container justify='center'>
                        <Grid item xs={12}>
                            <Logo path='/images/logo.png' alt='AFMC' className={classes.logo} />
                        </Grid>
                        <Grid item xs={9}/>
                        <Grid item xs={3} align='center'>
                            <LanguageToggle />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} lg={3} />
            </Grid>
        )
    }
}

export default withStyles(styles)(Header)

