import React, { Component } from "react";
import { Grid, Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import config from "../config";
import { TextInput } from "./Inputs";
import { Translate } from "react-localize-redux";
import Typography from "@material-ui/core/es/Typography/Typography";
import axios from "axios";
import postBody from "../postReq";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';

const styles = {
    form: {
        width: '100%'
    },
  subLabel: {
    fontWeight: "bold"
  },
  subDescription: {
    marginLeft: "51px"
  }
};

function filterSubscriptionStatus(data){
   const subscriptions = compareSubscriptions(data);
   const subArr = subscriptions.map(subscription => {
        return {[subscription.connectId]: subscription.value}
    });
    return Object.assign(...subArr);
}

function compareSubscriptions(data){

    const {
        subscriptions,
        subscribedPermissionLevels
    } = config.subscriptionSettings;

    let filteredSubs = data.subscriptions
        ? data.subscriptions.filter(dmSubscription => {
            const test = subscriptions.map(subscription => {
                return (
                    subscription.connectId ===
                    dmSubscription.subscriptionPermission.id
                );
            });
            return test.indexOf(false) !== -1;
        })
        : "";
    return subscriptions.map(subscription => {
            return {
              ...subscription,
              value:
                filteredSubs
                  .map(dmSubscription => {
                    if (
                      subscription.connectId ===
                      dmSubscription.subscriptionPermission.id
                    ){
                      return (
                        subscribedPermissionLevels.indexOf(
                          dmSubscription.subscriptionPermission.permission
                        ) !== -1
                      );
                    } else {
                        return false;
                    }
                  })
                  .indexOf(true) !== -1
            };
        })
}

class PureSearchForm extends Component {

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleSubmit(event) {
      alert(this.state.username)
      event.preventDefault()
      this.setState({showInfo: true});
    };

    render(){
        const searchField = config.fields[0];
        const { classes } = this.props;
        return(
          <Translate>
            {({ translate }) => (
              <form onSubmit={this.onSubmit} className={classes.form}>
                <Grid container spacing={32}>
                  <Grid item xs={12}>
                    <Grid container spacing={16} style={{width: '100%'}}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h2"
                          component="h2"
                          color="textSecondary"
                          gutterBottom
                        >
                          {translate("form.sections.search.header")}
                        </Typography>
                      </Grid>
                      <Grid key={searchField.name} item xs={12 / searchField.columns}>
                        <TextInput
                          name={searchField.name}
                          label={translate("form.fields."+ searchField.label.toLowerCase())}
                          onInputChange={this.handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant={config.formSettings.buttonVariant}
                      hidden
                    >
                      {translate("buttons.submit")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Translate>
        )
    }
}

class PurePrefForm extends Component {

  currentSubscriptionStatuses = this.props.data.id ? filterSubscriptionStatus(this.props.data) : {};
  state = {
    subscriptions: {
        ...this.currentSubscriptionStatuses
    },
    fetchedData: null,
    fullForm: false,
    contact: this.props.data,
    newUser: this.props.newUser,
  };

  componentDidMount() {
    this.setState({
      data: this.props,
    });
  }

  handleCheckbox(e, sub) {
    this.setState({
      subscriptions: {
        ...this.state.subscriptions,
        [sub]: e.target.checked
      }
    });
  }
  //working on
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    console.log(event.target.name);
  };

  //Checks if the current subscription is set to express if it is set the same index in unsub to Unsubscribe
  checkSubscription(index, subscriptions, subs){
    if(subscriptions[index].subscriptionPermission.permission === "Express"){
      subs.subscriptionPermissions[index].permission = "Unsubscribed";
      return true;
    } else {
      return false;
    }
  }

  splitSubscriptions = (event) => {
    const { newUser } = this.props;
    event.preventDefault();
    //TODO: NEED TO GRAB REQUEST TIME ASWELL
    const { email } = event.target;
    const { subscriptions } = this.state;
    const language = event.target[config.preferences.prefFields[0].name];
    var subs = postBody;
    subs.email = email.value;
    
    subs.fields[0].value = language.value;
    
    var hasSubs = false;
    //Look at the checked boxes.
    //If one is checked set it to Implied (If it's already express nothing will happen)
    //If one is unchecked check if it's current permission level is EXPRESS (subscribed).
    //YES send a POST req to unsubscribe for that subscritpion.
    //NO set it's permission to UNASSIGNED for the subscribe POST
    for (let i = 0; i < subs.subscriptionPermissions.length; i++){
      if (subscriptions[i+1] === true){ //Subscription
        subs.subscriptionPermissions[i].permission = "Express";
        hasSubs = true;
      } else {//Check if the current permission is express if it is, then unsubscribe else set to unassigned
        this.checkSubscription(i, this.props.data.subscriptions, subs);
        hasSubs = true;
      } 
    }
    let sStat, uStat;
    var spromise, upromise;
    const appid = "vD7@&DF8D!082d434$4C349D2c";
    const axiosConfig = {
      headers: {
        appid: appid
      }
    }
    //Both subscriptions and unsubscriptions go to the same endpoint
    if (hasSubs){
    spromise = axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/subscribe`, subs, axiosConfig)
      .then(response => {
        sStat = response.status;
        console.log(response);
      })
      .catch(err => {
        console.log(err);
        // alert(err.response.data.message);
      });
    }

    Promise.all([spromise, upromise]).then( () => {
      if (sStat === 200 || uStat === 200 || sStat === 201 || uStat === 201){
        alert("Permissions updated.");
      } else {
        alert ("Permissions unchanged.")
      }
    });
    
  
  };

  onSubmit = event => {
    event.preventDefault();
    this.splitSubscriptions(event);
  };




  render() {
    const { classes, data, email, newUser } = this.props;

    

    //PERSONAL INFO
    const fieldsData = data
      ? config.fields.map(field => {
          return {
            ...field,
            value: data.fields
              ? Object.keys(data.fields).reduce((acc, dmField) => {
                  const number = Number(dmField);
                  if (field.connectId === number) {
                    return acc + data.fields[number];
                  } else {
                    return acc;
                  }
                }, "")
              : ""
          };
        })
      : config.fields;

    // PREFERENCES
    const { prefFields } = config.preferences;

    const prefFieldsData = data
      ? prefFields.map(field => {
          return {
            ...field,
            value: data.fields.Language
              ? data.fields.Language
              : ""
          };
        })
      : prefFields;

      // const prefFieldsData = data
      // ? prefFields.map(field => {
      //     return {
      //       ...field,
      //       value: data.fields.Language
      //         ? Object.keys(data.fields).reduce((acc, dmField) => {
      //             const number = Number(dmField);
      //             if (field.connectId === number) {
      //               return data.fields[dmField];
      //             } else {
      //               return null;
      //             }
      //           }, "")
      //         : ""
      //     };
      //   })
      // : prefFields;
    // UNDEFINED console.log(this.data);
    console.log(data.fields);

    //SUBCRIPTIONS
    const subscriptionData = data.id ? compareSubscriptions(data) : config.subscriptionSettings.subscriptions;
    

    return (
      <Translate>
        {({ translate }) => (
          <form onSubmit={this.splitSubscriptions} className={classes.form}>
            <Grid container spacing={32}>
              <Grid item xs={12}>
                <Grid container spacing={16} style={{width: '100%'}}>
                  <Grid item xs={12}>
                    {newUser &&
                    <Typography
                      variant="body1"
                      component="p"
                      color="textPrimary"
                      gutterBottom
                    >
                      {translate("form.sections.personalInformation.newUser")}
                    </Typography>}
                    <Typography
                      variant="h2"
                      component="h2"
                      color="textSecondary"
                      gutterBottom
                    >
                      {translate("form.sections.personalInformation.header")}
                    </Typography>
                  </Grid>
                  {fieldsData.map(field => (
                    <Grid key={field.name} item xs={12 / field.columns}>
                      <TextInput
                        name={field.name}
                        label={translate("form.fields."+ field.label.toLowerCase())}
                        // label={this.props.originalEmail}
                        // disable={!!data.id}
                        value={email}
                        onInputChange={this.handleChange}
                        // onChange={this.handleChange}
                      />
                    </Grid>
                  ))[0]}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h2"
                      component="h2"
                      color="textSecondary"
                      gutterBottom
                    >
                      {translate("form.sections.preferences.header")}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      gutterBottom
                    >
                      {translate("form.sections.preferences.fields.languagePref.label")}
                    </Typography>
                  </Grid>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label={prefFieldsData[0].name}
                      name={prefFieldsData[0].name}
                      value={
                        !prefFieldsData[0].value && !this.state[prefFieldsData[0].name]
                        ? ''
                        : !this.state[prefFieldsData[0].name] 
                          ? prefFieldsData[0].value
                          : this.state[prefFieldsData[0].name]
                      }
                      
                      onChange={this.handleChange}
                      style={{flexDirection: 'row'}}
                    >
                      {prefFieldsData[0].options.map(
                        prefField => (
                          <FormControlLabel
                            key={prefField}
                            value={prefField}
                            control={<Radio />}
                            label={translate("form.sections.preferences.fields.languagePref.options." + prefField.toLowerCase())}
                          />
                          
                        )
                      )}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h2"
                      component="h2"
                      color="textSecondary"
                      gutterBottom
                    >
                      {translate("form.sections.subscriptions.header")}
                    </Typography>
                  </Grid>
                  {subscriptionData.map(sub => (
                    <Grid key={sub.name} item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              !sub.value && !this.state.subscriptions[sub.connectId]
                                ? false
                                : typeof this.state.subscriptions[sub.connectId] === "boolean"
                                ? this.state.subscriptions[sub.connectId]
                                : sub.value
                            }
                            onChange={e => {
                              this.handleCheckbox(e, sub.connectId);
                            }}
                            value={sub.name}
                            name={"sub-" + sub.connectId}
                          />
                        }
                        label={
                          <Typography
                            variant="body1"
                            color="primary"
                            className={classes.subLabel}
                          >
                            {sub.label}
                          </Typography>
                        }
                        disabled={false}
                      />
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.subDescription}
                      >
                        {translate(
                          "form.sections.subscriptions.options." +
                            sub.name.toLowerCase() +
                            ".description"
                        )}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="flex-start" spacing={16}>
                  <Grid item>
                    {data.id ?
                      <Button
                          type="submit"
                          variant={config.formSettings.buttonVariant}
                          hidden
                          
                      >
                        {translate("buttons.update")}
                      </Button>
                    :
                      <Button
                      type="submit"
                      variant={config.formSettings.buttonVariant}
                      hidden
                      >
                        {translate("buttons.submit")}
                      </Button>
                    }
                  </Grid>
                  <Grid item>
                    <Button
                        href="/"
                        variant={config.formSettings.buttonVariant}
                    >
                      {translate("buttons.back")}
                    </Button>
                      {/* <Button variant={config.formSettings.buttonVariant}
                      hidden onMouseDown={this.onSubmission}>Subscribe</Button> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Translate>
    );
  }
}

// const PrefForm = connect(mapStateToProps)(PurePrefForm);
// const prefF = connect(mapStateToProps)(PurePrefForm);
const PrefForm = withStyles(styles)(PurePrefForm);
const SearchForm = withStyles(styles)(PureSearchForm);

// export default connect(mapStateToProps)(PurePrefForm);

export { PrefForm, SearchForm };
