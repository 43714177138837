import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import {Translate} from "react-localize-redux";
import { SearchForm } from "./components/Forms";
import { Grid, Typography } from '@material-ui/core';
import axios from 'axios';
import { parse } from 'query-string';
import validator from "email-validator";
import triggerBody from './triggerReq';

class Main extends Component {

    state = {
        contactData: [],
        userSubmit: false,
        badEmail: false,
        emailMessage: false,
        email: ""
    };


    generateId = () => {
        var text = "sub" + Date.now();
        return text;
      };
/**
 * Sends a POST to the trigger/send endpoint using the email the user entered.
 */ 
    componentDidMount() {
        const { location } = this.props;
        const email = parse(location.search).email;
        const axiosConfig = { 
            headers : {
                appID: 'vD7@&DF8D!082d434$4C349D2c'
            }
        }
        if(email){
            if(validator.validate(email)){
                const sendBody = triggerBody;
                sendBody.send.email = email;
                sendBody.send.triggerName = "Verify User";
                

                axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/subscribe?email=` + email, axiosConfig)
                .then(response => {
                    try {
                        sendBody.send.uniqueID = response.data.content[0].fields.UniqueID
                    } catch {
                        sendBody.send.uniqueID = this.generateId();
                    }
                    console.log(sendBody.send.uniqueID);
                    axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/trigger/send`, sendBody.send, axiosConfig)
                    .then(res => {
                        this.setState({
                            userSubmit: true,
                            email: email
                        });
                    });
                }); 
                
                
            } else {
                this.setState({
                    badEmail: true
                })
            }
        }
    }

    handleSearch = event => {
        event.preventDefault();
    };

    render() {
        const { userSubmit, email, badEmail } = this.state;
        return (
            <Translate>
                {() => (
                    <Grid container direction='column' alignContent='center'>
                        {userSubmit ?
                        <Translate>
                            {({ translate }) => (
                                <Typography
                                    variant='body1'
                                    component='p'
                                    color="textPrimary"
                                    gutterBottom
                                >
                                    {translate("form.sections.search.updateEmailSent")}{email}
                                </Typography>
                            )}
                        </Translate>
                        : 
                        <>
                            <SearchForm onSubmit={this.handleSearch} />
                            {badEmail &&
                            <>
                            <br></br>
                            <Translate>
                                {({ translate }) => (
                                    <Typography
                                        variant='body1'
                                        component='p'
                                        color="textPrimary"
                                        gutterBottom
                                    >
                                        {translate("form.sections.search.badEmail")}
                                    </Typography>
                                )}
                            </Translate>
                            </>}
                            
                        </>}
                    </Grid>
                )}
            </Translate>
        )
    }
}

export default withRouter(Main);





// Backup
// componentDidMount() {
//     const { location } = this.props;
//     const email = parse(location.search).email;
//     const firstName = parse(location.search).firstname;
//     const lastName = parse(location.search).lastname;
//     const axiosConfig = { 
//         headers : {
//             appID: 'vD7@&DF8D!082d434$4C349D2c'
//         }
//     }
//     if(email){
//         if(validator.validate(email)){
//             axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/subscribe${location.search}`, axiosConfig).then(response => {
//             //gets good response from api sets state
//             if(response.status === 200){
//                 //contact exists
//                 this.setState({
//                     contactData: [...response.data.content],
//                     enableFullForm: true
//                 });
//                 console.log("Contact exists");
//                 //   store.dispatch(updateContact({...response.data.content}));
//                 // store.dispatch(updateContact(this.state));
//             } else if (response.status === 204){
//                 const sendBody = createBody;
//                 sendBody.fields[0].value = email;
//                 // sendBody.fields[1].value = firstName;
//                 // sendBody.fields[2].value = lastName;
//                 // sendBody.fields[3].value = email;
//                 // sendBody.fields[0].value = this.generateId(10);
//                 console.log(sendBody);
//                 axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/contact`, sendBody, axiosConfig)
//                     .then(res => {
//                         console.log("Contact created");
//                         axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/subscribe${location.search}`, axiosConfig).then(resp => {
//                         this.setState({
//                             contactData: [...resp.data.content],
//                             enableFullForm: true
//                         });
//                     });
//                 });
                
                
//             } else {
//                 console.log("Did not create or update contact");
//                 //no contact found. Do you want to sign up? Yea just do it automatically....
//                 const sendBody = postBody;
//                 sendBody.fields[1].value = firstName;
//                 sendBody.fields[2].value = lastName;
//                 sendBody.fields[3].value = email;
//                 sendBody.fields[0].value = this.generateId(10);
//             }
//             }).catch((err)=>{
//                 console.log(err);
//             })
//         }
//     }
// }