import React, { Component } from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import { Button } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";

const styles = (theme) => ({
    button: {
        color: theme.palette.primary.contrastText
    }
});

class LanguageToggle extends Component {

    render(){
        const { languages, activeLanguage, setActiveLanguage, classes } = this.props;

        //Languages is initially empty
        if (languages.length < 1) {
            return null;
        }

        const oppositeLanguage = activeLanguage.code === 'en' ? languages[1] : languages[0];

        return(
            <Button onClick={() => setActiveLanguage(oppositeLanguage.code)} className={classes.button}>
                <Translate id={"languageToggle." + oppositeLanguage.name.toLowerCase()}  />
            </Button>
        )
    }
}

export default withTheme()(withStyles(styles)(withLocalize(LanguageToggle)));

