import { createMuiTheme } from '@material-ui/core/styles';

const MuiTheme = createMuiTheme({
    palette: {
        primary: {
            main: 'rgb(211,49,40)',
            contrastText: "#FFFFFF"
        },
        secondary: {
            main: "#666666",
            light: "#999999",
            contrastText: "#FFFFFF"
        },
        error: {
            main: "#FF1111",
            contrastText: "#000000"
        },
        text: {
            primary: 'rgb(64,64,65)',
            secondary: "rgb(132,147,160)",
            hint: "#E50079"
        }
    },
    typography: {
        useNextVariants: true,
        fontFamily: ["PT Sans", "sans-serif"].join(","),
        textAlign: "left",
        h1: {
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            fontSize: '2rem'
        },
        h2: {
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            fontSize: '1.375rem'
        },
        h3: {
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            color: "#000000"
        },
        h4: {
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            color: "#000000"
        },
        h5: {
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            color: "#000000"
        },
        h6: {
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            color: "#000000"
        },
        body1: {
            fontSize: '20px',
            fontWeight: 100,
            lineHeight: '25px'
        },
        body2: {
            fontWeight: 100,
        },
        button: {
            fontSize: '20px',
            textTransform: 'capitalize'
        }
    },
    overrides: {
        MuiCheckbox: {
            root: {
                padding: '0 15px 0 0',
                fontSize: '36px !important'
            }
        },
        MuiFormControlLabel:{
            root:{
                marginLeft: '0'
            }
        },
        MuiDivider:{
            root:{
                margin: '20px 0 30px 0'
            }
        },
        MuiSvgIcon:{
            root:{
                fontSize: "inherit"
            }
        }
    }
});

export default MuiTheme;