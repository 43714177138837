const config = {
    languages:{
        en: 'localhost:3000',
        fr: 'localhost:3000/fr'
    },
    formSettings:{
      buttonVariant: 'outlined'
    },
    fieldSettings:{
      defaultVariant: 'outlined'
    },
    primaryField: {
        connectId: 1,
        label: 'Account Number'
    },
    fields:[
        
        {
            connectId: 4,
            name:'email',
            label:'Email',
            type:'email',
            placeholder: 'Type in last name',
            columns: 1
        },

    ],
    preferences:{
        prefFields:[
            {
                connectId: 15,
                name:'languagePref',
                label:'form.sections.preferences.fields.languagePref.label',
                options: ["English", "French"]
            },
        ],
    },
    subscriptionSettings: {
        subscribedPermissionLevels: ['Express','Implied','Implied-2yr','Implied-6mo'],
        subscriptions:[
            {
                connectId: 1,
                name: 'CCME News & Information',
                label: 'CCME News & Information',
            },
            {
                connectId: 2,
                name: 'AFMC News',
                label: 'AFMC News'
            },
            {
                connectId: 3,
                name: 'AFMC Member Business',
                label: 'AFMC Member Business'
            },
            {
                connectId: 4,
                name: 'CAPER News',
                label: 'CAPER News'
            }
        ]
    }
};

export default config


