import React, { Component } from 'react';
import axios from "axios";
import { withRouter } from 'react-router-dom'
import {Translate} from "react-localize-redux";
import { PrefForm } from "./components/Forms";
import config from "./config";
import { Grid, Typography, Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import triggerBody from './triggerReq';

class UpdateSubscription extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userEmail: "",
      appID: 'vD7@&DF8D!082d434$4C349D2c',
      enableForm: false,
      newUser: false,
    };
  }

/**
 * Checks whether all the subscriptions in passed in list are of permission Unassigned
 * @param  {[Array]} subscriptionList An array containing the subscriptionPermissions for the contact
 * @return {[boolean]} Returns true if all are unassigned, otherwise false
 */ 
  isNewUser = (subscriptionList) => {
    let isNew = true;
    for(let s of subscriptionList) {
      if(s.subscriptionPermission.permission !== "Unassigned") {
      isNew = false;
      }
    }
  return isNew;
 }  
  
/**
 * Set's the contactData state with the information returned from doing a GET req with the users email, if they exist. If the user 
 * does not exist a message will be shown to the user informing them to fill out the fields and create their account.
 * @param  {[string]} email A string containing the users email.
 * @return {[void]} Returns nothing.
 */
  getContact = (email) => {
    const axiosConfig = { 
      headers : {
          appID: 'vD7@&DF8D!082d434$4C349D2c'
      }
    }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/subscribe?email=${email}`, axiosConfig)
    .then(response => {
      this.setState({
        userEmail: email
      });
      if(response.status === 200) {
        console.log(response.data);
        if(this.isNewUser(response.data.content[0].subscriptions)) {
          this.setState({
            contactData: [...response.data.content],
            newUser: true,
            enableForm: true,
          });
        } else {
          this.setState({
            contactData: [...response.data.content],
            enableForm: true,
          });
        }
      } else {
        console.log("Something went wrong.");
      }
    });
  }
  
/**
 * POST's a req to the trigger/verify endpoint on page load to check if the ID in the link is valid. On resolution, 
 * if it's valid the users email will be recevied and passed to getContact.
 * else an error message will be logged and the page will tell the user the link is invalid and to resubscribe.
 */ 
  componentDidMount() {
    const axiosConfig = { 
      headers : {
          appID: 'vD7@&DF8D!082d434$4C349D2c'
      }
    }
    // eslint-disable-next-line no-restricted-globals
    let params = new URLSearchParams(location.search);
    const tokenId = params.get("id");
    if(tokenId !== "") {
      let sendBody = triggerBody;
      sendBody.verify.id = tokenId;
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/trigger/verify`, sendBody.verify, axiosConfig).then(response => {
        if(response.data.success) {
          this.getContact(response.data.email);
        } else {
          console.log("Something went wrong");
          console.log(response);
        }
      });
    }
  }

  render() {
    const { contactData, enableForm, userEmail, newUser } = this.state;
    return (
      <Translate>
        {({translate}) => (
          <Grid container direction='column' alignContent='center'>
            {enableForm ?
              contactData.map((elem, index) => {
                  return <><PrefForm key={elem.id} newUser={newUser} email={userEmail} data={elem}/>{contactData.length !== index + 1 ? <Divider key={elem.id} />: ""}</>
              })
            :
            <>
              <Typography 
                variant="body1"
                component="p"
                color="textPrimary"
                gutterBottom
              >
                  {translate(
                  "update.invalidId"
                )}
              </Typography>
              <br></br>
              <Grid item xs={12} container direction="column" alignItems="center" justifycontent="center">
                <Button
                  href="/"
                  variant={config.formSettings.buttonVariant}
                  >
                    Submit
                </Button>
              </Grid>
            </>
            }
          </Grid>
        )}
      </Translate>
    );
  }
};

export default withRouter(UpdateSubscription);
