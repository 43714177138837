import React, { Component } from 'react';
import { Typography, Grid } from "@material-ui/core";
import { withStyles, withTheme } from '@material-ui/core/styles'
import { Translate } from "react-localize-redux";

const styles = (theme) => ({
    root: {
        marginTop: '30px',
        minHeight: '150px'
    },
    text: {
        color: theme.palette.primary.contrastText
    }
});

class Footer extends Component {

    render(){
        const { classes } = this.props;
        return(
            <Grid container justify='center' className={classes.root}>
                <Grid item md={2} lg={3} />
                <Grid item xs={12} md={8} lg={6} >
                    <Grid container direction='column' alignContent='center'>
                        <Typography variant='body2' component='p' className={classes.text} align="center">
                            <Translate id='footer.copyright'/>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={2} lg={3} />
            </Grid>
        )
    }
}

export default withTheme()(withStyles(styles)(Footer));

