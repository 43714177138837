const triggerBody = {
    //testing
    send: {
        email: "",
        uniqueId: "",
        triggerName: "Verify User",
        company: "AFMC"
    },
    verify: {
        id: ""
    }
};

export default triggerBody;