import React from 'react'
import {Translate} from "react-localize-redux";
import {Grid, Typography, Divider} from "@material-ui/core";

export default function Title(){
    return(
        <Grid container direction='column' alignContent='center'>
            <Typography variant='h1' component='h1' color="textSecondary" gutterBottom>
                <Translate id='title'/>
            </Typography>
            <Typography variant='body1' component='p' color="textPrimary" gutterBottom>
                <Translate id='introduction'/>
            </Typography>
            <Divider />
        </Grid>
    )
}